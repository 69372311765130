import React from 'react';
import styles from './about.module.css';
import Modal from '../../components/modal/modal';
import multiplatformMockup from '../../assets/multiplatform-mockup.jpg';
import Divider from '../../components/divider/divider';
import {ReactComponent as Chevron} from '../../assets/chevron-down.svg';
import icon from '../../assets/icon.png';
import Button from '../../components/button/button';

const About: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <Modal className={styles.modal} title="About/Mission">
        <div className={styles.header}>
          <h2>Good habits start young.</h2>
          <img src={multiplatformMockup} alt="Multiplatform mockup" />
        </div>
        <div className={`${styles.shadowContainer} ${styles.about}`}>
          <h3>What is Mindiful®?</h3>
          <Divider className={styles.divider} />
          <p>A Children's Mental Health and Wellness Solution.</p>
          <p>We are a progressive, enriching, and entertaining children's mental health software platform comprised of early learning mental health exercises and insightful proprietary tools in an immersive storybook interface.</p>     
        </div>
        <div className={styles.stepByStep}>
          <p>Lack of youth mental health resources.</p>
          <Chevron className={styles.chevron} />
          <img src={icon} alt="Icon" />
          <Chevron className={styles.chevron} />
          <p>New generations better equipped with techniques to promote a mentally healthy lifestyle.</p>
        </div>
        <div className={styles.kidsHandsImage}>
          <h3>Mental health resources for a better future.</h3>
        </div>
        <div className={styles.missionTitle}>
          <h3>What is Mindiful®'s mission?</h3>
          <Divider className={styles.divider} />
        </div>
        <div className={`${styles.shadowContainer} ${styles.mission}`}>
          <p>Our mission is to make early-stage mental health</p>
          <p style={{color: '#FFAF15'}}>universally accessible to all little ones.</p>
        </div>
        <div className={styles.missionEnd}>
          <p>We at Mindiful® believe proactive children’s mental health resources should be universally accessible and become a natural part of early education.</p>
        </div>
        <div className={`${styles.shadowContainer} ${styles.community}`}>
          <h3>Our commitment to the Mindiful® community:</h3>
          <div className={styles.innerDivider} />
          <div className={styles.communityList}>
            <div className={styles.item}>
              <div className={styles.point}><div /></div>
              <p>To provide kid-first educational yet entertaining innovations in conjunction with mental health professionals & clinicians.</p>
            </div>
            <div className={styles.item}>
              <div className={styles.point}><div /></div>
              <p>Ensure transparency and safety in data practices; Providing a human-first technology approach.</p>
            </div>
            <div className={styles.item}>
              <div className={styles.point}><div /></div>
              <p>To connect as many children globally as possible to the resources they need to grow into mentally healthy adults.</p>
            </div>
            <div className={styles.item}>
              <div className={styles.point}><div /></div>
              <p>To have our basic platform always be free.</p>
            </div>
          </div>
        </div>
        <div className={styles.kidParentImage}>
          <p>Why learn about mental health retroactively as an adult?<br />Mindiful® is the foundational proactive solution.</p>
        </div>
        <div className={styles.moreInfo}>
          <p>For more information on becoming a Mindiful® Mental Health Ally or to give feedback, email us!</p>
          <p>We look forward to connecting with you.</p>
        </div>
        <Button className={styles.contactButton} onClick={() => window.open('mailto:hello@mindiful.io')}>
          hello@mindiful.io
        </Button>
      </Modal>
    </div>
  );
};

export default About;