import React from 'react';
import styles from './contact.module.css';
import Modal from '../../components/modal/modal';
import Divider from '../../components/divider/divider';
import Button from '../../components/button/button';
import { ReactComponent as FacebookLogo } from '../../assets/facebook.svg';
import { ReactComponent as InstagramLogo } from '../../assets/instagram.svg';

const Contact: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <Modal className={styles.modal}>
        <div className={styles.header}>
          <div>
            <h1>Contact</h1>
            <Divider className={styles.divider} />
          </div>
        </div>
        <div className={styles.text}>
          <p style={{fontSize: '1.4rem'}}>Questions, comments,<br />feedback, or suggestions?</p>
          <p>We are here to help!</p>
        </div>
        <Button className={styles.button} onClick={() => window.open('mailto:hello@mindiful.io')}>
          hello@mindiful.io
        </Button>
        <div className={styles.social}>
          <p>Connect with us on social:</p>
          <a href="https://www.facebook.com/mindiful" target="_blank" rel="noreferrer"><FacebookLogo className={styles.facebook} /></a>
          <a href="https://www.instagram.com/mindiful.io" target="_blank" rel="noreferrer"><InstagramLogo className={styles.instagram} /></a>
        </div>
        <div className={styles.socialTags}>
          <p>@Mindiful.io</p>
          <p>#Mindiful</p>
          <p>#Mindifulforkids</p>
        </div>
      </Modal>
    </div>
  );
};

export default Contact;