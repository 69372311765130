import React from 'react';
import styles from './divider.module.css';

interface Props {
  className?: string;
}

const Divider: React.FC<Props> = props => {
  return (
    <div className={`${styles.divider} ${props.className || ''}`} />
  );
};

export default Divider;