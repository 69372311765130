import React from 'react';
import styles from './button.module.css';

interface Props {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const Button: React.FC<Props> = props => {
  return (
    <div
      className={`${styles.bg} ${props.className} ${props.disabled ? styles.disabled : ''}`}
      onClick={props.onClick}
    >
      <div className={styles.wrap}>
        <p>{props.children}</p>
      </div>
    </div>
  );
};

export default Button;