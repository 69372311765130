import React from 'react';
import styles from './do-not-sell.module.css';
import Modal from '../../components/modal/modal';
import showdown from 'showdown';
import doNotSell from '../../markdown/do-not-sell.md';

const MarkdownConverter = new showdown.Converter();

const DoNotSell: React.FC = () => {
  const [contentHtml, setContentHtml] = React.useState<string>();

  React.useEffect(() => {
    fetch(doNotSell)
      .then(r => r.text())
      .then(content => setContentHtml(MarkdownConverter.makeHtml(content)));
  }, []);

  return (
    <div className={styles.wrap}>
      <Modal className={styles.modal} title="Do Not Sell My Personal Information">
        {
          contentHtml ?
            <div dangerouslySetInnerHTML={{__html: contentHtml}} />
            : null
        }
      </Modal>
    </div>
  );
};

export default DoNotSell;