import React from 'react';
import Button from '../button/button';
import styles from './header.module.css';
import { ReactComponent as MenuIcon } from '../../assets/menu-icon.svg';
import logo from '../../assets/logo.png';
import starIcon from '../../assets/star.png';
import Menu from '../menu/menu';
import { Link, useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.header}>
        <div className={styles.menuIcon} onClick={() => setOpenMenu(val => !val)}>
          <MenuIcon />
        </div>
        <Link className={styles.logo} to="/">
          <img src={logo} alt="Mindiful" />
          <div>
            <img src={starIcon} alt="Star" />
            <p>Mental Health Starts Here</p>
          </div>
        </Link>
        <Button className={styles.button} onClick={() => navigate('/download')}>
          Download Now
        </Button>
      </div>
      <Menu open={openMenu} onClose={() => setOpenMenu(false)} />
    </>
  );
};

export default Header;