import React from 'react';
import styles from './resources.module.css';
import Modal from '../../components/modal/modal';
import res1 from '../../assets/res1.png';
import res2 from '../../assets/res2.png';
import res3 from '../../assets/res3.png';
import res4 from '../../assets/res4.png';
import res5 from '../../assets/res5.png';
import res6 from '../../assets/res6.png';
import social1 from '../../assets/social1.jpg';
import social2 from '../../assets/social2.jpg';
import social3 from '../../assets/social3.jpg';
import social4 from '../../assets/social4.jpg';
import Divider from '../../components/divider/divider';
import { ReactComponent as FacebookLogo } from '../../assets/facebook.svg';
import { ReactComponent as InstagramLogo } from '../../assets/instagram.svg';

const resources = [
  {title: 'Parent/Guardian User Guide', img: res1, pdf: '/Mindiful Parent Guardian Guide.pdf'},
  {title: 'Teacher/Clinician User Guide', img: res2, pdf: '/Mindiful Teacher Clinician Guide.pdf'},
  {title: 'Journal User Guide', img: res3, pdf: '/Mindiful Journal Guide.pdf'},
  {title: 'Mindiful Map', img: res4, pdf: '/Mindiful Map.pdf'},
  {title: 'Download Journal/ Analytic Reports', img: res5, pdf: '/Mindiful Download Journal Reports Guide.pdf'},
  {title: 'Data Backup/ Transfer', img: res6, subtitle: '(Import and export)', pdf: '/Mindiful Data Backup Transfer Guide.pdf'},
];

const social = [
  {title: 'Social Square 1', img: social1, fullSizeImg: '/social1.jpg'},
  {title: 'Social Square 2', img: social2, fullSizeImg: '/social2.jpg'},
  {title: 'Social Square 3', img: social3, fullSizeImg: '/social3.jpg'},
  {title: 'Social Square 4', img: social4, fullSizeImg: '/social4.jpg'},
];

const Resources: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <Modal className={styles.modal} title="Resource Guides" subtitle="Click to Download.">
        <div className={styles.resources}>
          {
            resources.map((item, index) => (
              <a key={index} className={styles.item} href={item.pdf} target="_blank" rel="noreferrer">
                <img src={item.img} alt={item.title} />
                <p className={styles.title}>{item.title}</p>
                {item.subtitle ? <p>{item.subtitle}</p> : null}
              </a>
            ))
          }
        </div>
        <div className={styles.socialHeader} id="sharable">
          <h2>Social Sharables</h2>
          <div>
            <Divider className={styles.divider} />
            <a href="https://www.facebook.com/mindiful" target="_blank" rel="noreferrer"><FacebookLogo className={styles.facebook} /></a>
            <a href="https://www.instagram.com/mindiful.io" target="_blank" rel="noreferrer"><InstagramLogo className={styles.instagram} /></a>
            <a href="https://www.instagram.com/mindiful.io" target="_blank" rel="noreferrer"><p>@mindiful.io</p></a>
          </div>
        </div>
        <div className={styles.social}>
          {
            social.map((item, index) => (
              <a key={index} className={styles.item} href={item.fullSizeImg} target="_blank" rel="noreferrer">
                <img src={item.img} alt={item.title} />
                <p>{item.title}</p>
              </a>
            ))
          }
        </div>
      </Modal>
    </div>
  );
};

export default Resources;