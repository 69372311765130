import React from 'react';
import styles from './search.module.css';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';

interface Props {
  className?: string;
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
}

const Search: React.FC<Props> = props => {
  return (
    <div className={`${styles.search} ${props.className}`}>
      <div>
        <SearchIcon className={styles.icon} />
        <input className={styles.input} value={props.value} onChange={e => props.onChange(e.currentTarget.value)} placeholder={props.placeholder || 'Type here to search'} />
      </div>
    </div>
  );
};

export default Search;