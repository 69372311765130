import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/layout/layout';
import About from './containers/about/about';
import Accessibility from './containers/accessibility/accessibility';
import Contact from './containers/contact/contact';
import DoNotSell from './containers/do-not-sell/do-not-sell';
import DownloadNow from './containers/download-now/download-now';
import Faq from './containers/faq/faq';
import Home from './containers/home/home';
import Premium from './containers/premium/premium';
import PrivacyPolicy from './containers/privacy-policy/privacy-policy';
import Resources from './containers/resources/resources';
import Reviews from './containers/reviews/reviews';
import TermsOfUse from './containers/terms-of-use/terms-of-use';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-2YDD311F6V');

const App: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: location.pathname });
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/download" element={<DownloadNow />} />
        <Route path="/premium" element={<Premium />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/accessibility" element={<Accessibility />} />
        <Route path="/do-not-sell" element={<DoNotSell />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reviews" element={<Reviews />} />
      </Route>
    </Routes>
  );
};

export default App;
