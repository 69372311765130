import React from 'react';
import styles from './download-now.module.css';
import Modal from '../../components/modal/modal';
import Button from '../../components/button/button';
import appStoreBadge from '../../assets/app-store-badge.svg';
import playStoreBadge from '../../assets/google-play-badge.png';
import desktop from '../../assets/desktop.jpg';
import mobile from '../../assets/mobile.jpg';
import web from '../../assets/web.jpg';
import Divider from '../../components/divider/divider';
import windowsStoreBadge from '../../assets/windows-store-badge.png';
import ReactGA from 'react-ga4';

const DownloadNow: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <Modal className={styles.modal} title="Download Now">
        <div className={styles.platformWrap}>
          <div>
            <h2>Desktop Application</h2>
            <img className={styles.platformImage} src={desktop} alt="Desktop app" />
          </div>
          <div className={styles.buttons}>
            <Button className={styles.button} onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'App Store download button click',
              });
              window.open('https://apps.apple.com/us/app/mindiful/id1383607910', '_blank');
            }}>
              <img src={appStoreBadge} alt="Download from App Store" />
            </Button>
            <Button className={styles.button} onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'Windows Store download button click',
              });
              window.open('', '_blank');
            }}>
              <img src={windowsStoreBadge} alt="Download from Windows Store" />
            </Button>
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.platformWrap}>
          <div>
            <h2>Mobile Application</h2>
            <img className={styles.platformImage} src={mobile} alt="Mobile app" />
          </div>
          <div className={styles.buttons}>
            <Button className={styles.button} onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'App Store download button click',
              });
              window.open('https://apps.apple.com/us/app/mindiful/id1383607910', '_blank');
            }}>
              <img  src={appStoreBadge} alt="Download from App Store" />
            </Button>
            <Button className={styles.button} onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'Google Play download button click',
              });
              window.open('https://play.google.com/store/apps/details?id=io.mindiful.app', '_blank');
            }}>
              <img src={playStoreBadge} alt="Download from Google Play" />
            </Button>
          </div>
        </div>
        <div className={styles.webPlatformWrap}>
          <Divider className={styles.divider} />
          <div className={styles.platformWrap}>
            <div>
              <h2>Web Application</h2>
              <img className={styles.platformImage} src={web} alt="Web app" />
            </div>
            <div className={styles.buttons}>
              <Button className={styles.button} onClick={() => {
                ReactGA.event({
                  category: 'Click',
                  action: 'Web app link click',
                });
                window.open('https://app.mindiful.io', '_blank');
              }}>
                Continue to Sign In
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DownloadNow;