import React from 'react';
import styles from './home.module.css';
import bgVideoPoster from '../../assets/home-bg-video-poster.jpg';
import bgVideo from '../../assets/home-bg-video.m4v';
import Button from '../../components/button/button';
import mindifulIcon from '../../assets/icon.png';
import appStoreBadge from '../../assets/app-store-badge.svg';
import playStoreBadge from '../../assets/google-play-badge.png';
import featuredImage from '../../assets/home-featured.png';
import promoVideo from '../../assets/promo.mp4';
import patentedBadge from '../../assets/patented-badge.jpg';
import { ReviewStandard } from '../reviews/reviews';
import arrow from '../../assets/arrow-left.png';
import promoPoster from '../../assets/video-poster.jpg';
import birdGrey from '../../assets/bird-grey.png';
import birdWhite from '../../assets/bird-white.png';
import ReactGA from 'react-ga4';

export const reviews = [
  {
    title: 'Amazing therapeutic aid for kids!',
    content: <p>“I didn't have high expectations for this app only because my kids are so frenetic, but a friend recommended it so I tried. <b>This app has been the only thing that has been able to calm my kids down and focus them for long periods of time.</b> The phone is nearly impossible to take away from them at this point, so it's very convenient to just open this app and <b>watch them become engaged immediately</b>.”</p>,
    author: 'Seanachie23',
  },
  {
    title: 'Important for every family.',
    content: <p>“'Mindiful' is a simple yet effective tool for any developing child targeting their mental health and well being. On top of that, <b>it's easy for my 3 and 5 year old to navigate through the app on their own.</b>”</p>,
    author: "Patrickdoesn'tknow",
  },
  {
    title: 'Mental Health for Kids!',
    content: <p>“I was randomly searching for some mental health apps and came across 'Mindiful'. The integration and functionality was a wonderful experience. The animation was pleasant and very well designed. It was educational, entertaining and fun. This is a great introduction into mental health for kids. <b>I definitely recommend using this app daily to improve mental health.</b>”</p>,
    author: 'richardp11',
  },
  {
    title: "Grateful for 'Mindiful'.",
    content: <p>“We all love how easy this app makes mindfulness a part of life with captivating, artistic illustrations too! <b>Parent portal helps guide and monitor children's use and our working together - perfect tool.</b><br /><br />Thanks!”</p>,
    author: 'Happy Teacher & Mom',
  },
  {
    title: 'A great app to teach breathing.',
    content: <p>“<b>I've been looking for a way to help teach my 2 year old how to deep breathe, and I think I've found the easiest way!</b> Deep breathing is known to help reduce stress and it's one of the best ways for everyone to calm themselves. My little gut loves the reptile relaxation the best. He cuddles up to me when told to get into a comfy position it's soothing for us both.”</p>,
    author: 'mama_withagoal',
  },
  {
    title: 'What a great app for kids.',
    content: <p>“I wish I learned these techniques when I was a child. <b>Between breathing and meditation to journaling and self-expression, this app is an awesome format for young people to advance their self-help know-how.</b><br /><br />Check it out and watch your child live a more stress-free life.”</p>,
    author: 'Toasli',
  },
];

const Home: React.FC = () => {
  const feedbackRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={styles.home} style={{backgroundImage: `url(${bgVideoPoster})`}}>
        <video
          src={bgVideo}
          poster={bgVideoPoster}
          muted
          playsInline
          loop
          onLoadedMetadata={e => e.currentTarget.play()}
        />
        {/*<Button className={styles.premiumPackagesButton} onClick={() => navigate('/premium')}>
          View Premium Packages
        </Button>*/}

        <div className={styles.content}>
          <div className={styles.top}>
            <img src={featuredImage} alt="Mindiful" />
            <div className={styles.buttons}>
              <Button className={styles.button} onClick={() => {
                ReactGA.event({
                  category: 'Click',
                  action: 'App Store download button click',
                });
                window.open('https://apps.apple.com/us/app/mindiful/id1383607910', '_blank');
              }}>
                <img src={appStoreBadge} alt="Download from App Store" />
              </Button>
              <Button className={styles.button} onClick={() => {
                ReactGA.event({
                  category: 'Click',
                  action: 'Google Play download button click',
                });
                window.open('https://play.google.com/store/apps/details?id=io.mindiful.app', '_blank');
              }}>
                <img src={playStoreBadge} alt="Download from Google Play" />
              </Button>
            </div>
          </div>

          <div className={styles.bottom}>
            <img className={styles.mindifulIcon} src={mindifulIcon} alt="Mindiful icon" />
            <div className={styles.text}>
              <h1>Welcome to Mindiful!</h1>
              <p>A Children's Mental Health and Wellness Solution.</p>
              <p className={styles.freeDownload}>Free Download <span className={styles.desktopOnly}>(Recommended).</span></p>
            </div>
            <a
              href="https://app.mindiful.io"
              target="_blank"
              rel="noreferrer"
              className={`${styles.webAppLink} ${styles.desktopOnly}`}
              onClick={() => {
                ReactGA.event({
                  category: 'Click',
                  action: 'Web app link click',
                });
              }}
            >
              I would like to continue to web use and understand my data is NOT stored without a premium data storage account.
            </a>
          </div>
          <div className={styles.buttons}>
            <Button className={styles.button} onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'App Store download button click',
              });
              window.open('https://apps.apple.com/us/app/mindiful/id1383607910', '_blank');
            }}>
              <img src={appStoreBadge} alt="Download from App Store" />
            </Button>
            <Button className={styles.button} onClick={() => {
              ReactGA.event({
                category: 'Click',
                action: 'Google Play download button click',
              });
              window.open('https://play.google.com/store/apps/details?id=io.mindiful.app', '_blank');
            }}>
              <img src={playStoreBadge} alt="Download from Google Play" />
            </Button>
          </div>
          <img className={styles.birdWhite} src={birdWhite} alt="Bird" />
          <img className={styles.birdGrey} src={birdGrey} alt="Bird" />
        </div>
      </div>
      <div className={styles.promoContainer}>
        <div className={styles.videoContainer}>
          <div className={styles.mobileOnly}>
            <h2>Mindiful in Action</h2>
            <p>Click to enlarge video.</p>
          </div>
          <div className={styles.video}>
            <video src={promoVideo} controls poster={promoPoster} />
          </div>
          <div className={styles.desktopOnly}>
            <h2>Mindiful in Action</h2>
            <p>Click to enlarge video.</p>
          </div>
        </div>
        <img src={patentedBadge} alt="Patented" className={styles.patentedBadge} />
        <img className={styles.birdGrey} src={birdGrey} alt="Bird" />
      </div>
      <div className={styles.feedbackWrap}>
        <div className={styles.arrow} style={{left: '1rem'}} onClick={() => feedbackRef.current?.scrollBy({left: -500, behavior: 'smooth'})}>
          <div>
            <img src={arrow} alt="Left" className={styles.leftArrow} />
          </div>
        </div>
        <div className={styles.feedbackContainer} ref={feedbackRef}>
          {reviews.map(item => <ReviewStandard key={item.title} title={item.title} content={item.content} author={item.author} className={styles.feedback} />)}
        </div>
        <div className={styles.arrow} style={{right: '1rem'}} onClick={() => feedbackRef.current?.scrollBy({left: 500, behavior: 'smooth'})}>
          <div>
            <img src={arrow} alt="Left" className={styles.rightArrow} />
          </div>
        </div>
      </div>
      <div className={`${styles.mobileFeedbackWrap} ${styles.mobileOnly}`}>
        <h2>Reviews</h2>
        {reviews.map(item => <ReviewStandard key={item.title} title={item.title} content={item.content} author={item.author} className={styles.mobileFeedback} />)}
      </div>
    </>
  );
};

export default Home;