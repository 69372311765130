import React from 'react';
import Button from '../button/button';
import styles from './input.module.css';

interface Props {
  type: React.HTMLInputTypeAttribute;
  placeholder: string;
  value: string;
  onChange: (val: string) => void;
  onSubmit: () => void;
  submitText: string;
  className?: string;
  disabled?: boolean;
}

const Input: React.FC<Props> = props => {
  return (
    <div className={`${styles.wrap} ${props.className}`}>
      <input value={props.value} type={props.type} placeholder={props.placeholder} onChange={e => props.onChange(e.currentTarget.value)} />
      <Button disabled={props.disabled} onClick={props.onSubmit}>{props.submitText}</Button>
    </div>
  );
};

export default Input;