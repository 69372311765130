import React from 'react';
import styles from './terms-of-use.module.css';
import Modal from '../../components/modal/modal';
import showdown from 'showdown';
import termsOfUse from '../../markdown/terms-of-use.md';

const MarkdownConverter = new showdown.Converter();

const TermsOfUse: React.FC = () => {
  const [contentHtml, setContentHtml] = React.useState<string>();

  React.useEffect(() => {
    fetch(termsOfUse)
      .then(r => r.text())
      .then(content => setContentHtml(MarkdownConverter.makeHtml(content)));
  }, []);

  return (
    <div className={styles.wrap}>
      <Modal className={styles.modal} title="Terms of use">
        {
          contentHtml ?
            <div dangerouslySetInnerHTML={{__html: contentHtml}} />
            : null
        }
      </Modal>
    </div>
  );
};

export default TermsOfUse;