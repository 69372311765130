import React, { ReactElement } from 'react';
import styles from './reviews.module.css';
import Modal from '../../components/modal/modal';
import FiveStars from '../../components/five-stars/five-stars';
import Divider from '../../components/divider/divider';

export const reviews = [
  {
    title: 'What a great app for kids.',
    content: <p>“I wish I learned these techniques when I was a child. Between breathing and meditation to journaling and self-expression, this app is an awesome format for young people to advance their self-help know-how.<br /><br />Check it out and watch your child live a more stress-free life.”</p>,
    author: 'Toasli',
  },
  {
    title: 'A great app to teach breathing.',
    content: <p>“I've been looking for a way to help teach my 2 year old how to deep breathe, and I think I've found the easiest way! Deep breathing is known to help reduce stress and it's one of the best ways for everyone to calm themselves. My little gut loves the reptile relaxation the best. He cuddles up to me when told to get into a comfy position it's soothing for us both.”</p>,
    author: 'mama_withagoal',
  },
  {
    title: 'Mental Health for Kids!',
    content: <p>“I was randomly searching for some mental health apps and came across 'Mindiful'. The integration and functionality was a wonderful experience. The animation was pleasant and very well designed. It was educational, entertaining and fun. This is a great introduction into mental health for kids. I definitely recommend using this app daily to improve mental health.”</p>,
    author: 'richardp11',
  },
  {
    title: 'Important for every family.',
    content: <p>“'Mindiful' is a simple yet effective tool for any developing child targeting their mental health and well being. On top of that, it's easy for my 3 and 5 year old to navigate through the app on their own.”</p>,
    author: "Patrickdoesn'tknow",
  },
  {
    title: 'Mental health for the whole family.',
    content: <p>“Perfect way to introduce children to a meditation practice. Exciting to see an app that can help parents incorporate mindfulness into daily life in a thoughtful and artistic way and to start the conversation about mental health. Love the parent portal feature too!”</p>,
    author: 'Mindful Master',
  },
  {
    title: 'Not just for moms!',
    content: <p>“'Mindiful' is exactly what I've been looking for to use in the growth and development of the kids I nanny for. Being a nanny is a big task and finding interesting, fun ways to help kids become more aware of their state of mind and overall mental and physical health can be difficult. 'Mindiful' helps me help them. It's fun, educational and really aids in the progress of their growth.”</p>,
    author: 'Aubz1',
  },
  {
    title: "Grateful for 'Mindiful'.",
    content: <p>“We all love how easy this app makes mindfulness a part of life with captivating, artistic illustrations too! Parent portal helps guide and monitor children's use and our working together - perfect tool.<br /><br />Thanks!”</p>,
    author: 'Happy Teacher & Mom',
  },
  {
    title: 'Enjoyable & Calming',
    content: <p>“Enjoyable and calming experience for young people full of cute creatures and locations with mantras and interesting activities that will relax and engage.”</p>,
    author: 'Kazamy',
  },
  {
    title: 'So Good',
    content: <p>“Super smart, great tool for kids, really well done.”</p>,
    author: 'Blick 122',
  },
];

const StarsAuthor: React.FC<{author: string}> = props => {
  return (
    <div className={styles.starsAuthor}>
      <div className={styles.starsWrap}>
        <FiveStars />
      </div>
      <p>{props.author}</p>
    </div>
  );
};

export const ReviewStandard: React.FC<{className?: string; title: string; content: ReactElement; author: string}> = props => {
  return (
    <div key={props.title} className={`${styles.review} ${props.className}`}>
      <div>
        <h3>{props.title}</h3>
        <StarsAuthor author={props.author} />
      </div>
      {props.content}
    </div>
  );
};

const Reviews: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <Modal className={styles.modal}>
        <div className={styles.header}>
          <h1>Reviews</h1>
          <FiveStars className={styles.fiveStars} />
          <Divider className={styles.divider} />
        </div>
        <div className={styles.featured}>
          <div className={styles.featuredHeader}>
            <h3>Amazing therapeutic aid for kids!</h3>
            <StarsAuthor author="Seanachie23" />
          </div>
          <p>“I didn't have high expectations for this app only because my kids are so frenetic, but a friend recommended it so I tried. <b>This app has been the only thing that has been able to calm my kids down and focus them for long periods of time.</b> The phone is nearly impossible to take away from them at this point, so it's very convenient to just open this app and <b>watch them become engaged immediately</b>. More importantly I can get some <b>peace and quiet as they learn about breathing, reflection, and building calmness into their digitally connected 24/7 lives</b>.”</p>
        </div>
        <div className={styles.reviews}>
          {
            reviews.slice(0, reviews.length - 2).map(item => <ReviewStandard key={item.title} title={item.title} content={item.content} author={item.author} />)
          }
          <div>
            <div key={reviews[reviews.length - 2].title} className={styles.review} style={{marginBottom: '0.5rem'}}>
              <div>
                <h3>{reviews[reviews.length - 2].title}</h3>
                <StarsAuthor author={reviews[reviews.length - 2].author} />
              </div>
              {reviews[reviews.length - 2].content}
            </div>
            <div key={reviews[reviews.length - 1].title} className={styles.review}>
              <div>
                <h3>{reviews[reviews.length - 1].title}</h3>
                <StarsAuthor author={reviews[reviews.length - 1].author} />
              </div>
              {reviews[reviews.length - 1].content}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Reviews;