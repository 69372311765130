import React from 'react';
import styles from './premium.module.css';
import Modal from '../../components/modal/modal';
import Divider from '../../components/divider/divider';
//import Button from '../../components/button/button';
import { ReactComponent as Checkmark } from '../../assets/checkmark.svg';
import { ReactComponent as XIcon } from '../../assets/x.svg';

const Premium: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <Modal className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.titleWrap}>
            <h1>Mindiful Premium</h1>
            <div>
              <Divider className={styles.divider} />
              <p>We at Mindiful® value children's privacy.</p>
            </div>
          </div>
          {/*<Button className={styles.purchaseButton}>
            Purchase Plus
          </Button>*/}
        </div>
        <div className={styles.plansWrap}>
          <div>
            <div className={styles.planOptionTitle}>
              <div>
                <h2>Free</h2>
              </div>
            </div>
            <ul>
              <li>
                <Checkmark className={styles.checkmark} />
                <p>Sensitive Data stays within device unless manually imported/exported</p>
              </li>
              <li>
                <Checkmark className={styles.checkmark} />
                <p>Up to 10 Users</p>
              </li>
              <li>
                <XIcon className={styles.xIcon} />
                <p>Speech to Text Transcription Of Journal Entries</p>
              </li>
              <li>
                <XIcon className={styles.xIcon} />
                <p>Help Support our Development & Resources for mental health</p>
              </li>
              <li>
                <XIcon className={styles.xIcon} />
                <p>NO Cross-Device Data Storage</p>
              </li>
              <li>
                <XIcon className={styles.xIcon} />
                <p>NO Cross-Device Data Backups</p>
              </li>
            </ul>
            {/*<div className={styles.price}>
              <p>$0</p>
              <p>Per/Month</p>
            </div>*/}
          </div>
          <div>
            <div className={styles.planOptionTitle}>
              <div>
                <h2>Plus</h2>
                <p>(Speech to Text Upgrade)</p>
              </div>
            </div>
            <ul>
              <li>
                <Checkmark className={styles.checkmark} />
                <p>Sensitive Data stays within device unless manually imported/exported</p>
              </li>
              <li>
                <Checkmark className={styles.checkmark} />
                <p>Up to 10 Users</p>
              </li>
              <li>
                <Checkmark className={styles.checkmark} />
                <p>Speech to Text Transcription Of Journal Entries</p>
              </li>
              <li>
                <Checkmark className={styles.checkmark} />
                <p>Help Support our Development & Resources for mental health</p>
              </li>
              <li>
                <XIcon className={styles.xIcon} />
                <p>NO Cross-Device Data Storage</p>
              </li>
              <li>
                <XIcon className={styles.xIcon} />
                <p>NO Cross-Device Data Backups</p>
              </li>
            </ul>
            {/*<div className={styles.price}>
              <p>$1.99</p>
              <p>Per/Month</p>
            </div>*/}
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.info}>
          <p><b>When are Premium Cross-Device Accounts Going to Be Available?</b></p>
          <p>At the core of our platform is our mission of providing free universal access to children’s mental health resources and being clear and transparent with data practices. With this mission in mind, we developed the free basic version and Plus: Speech-to Text upgrade first.</p>
          <p>We are now developing our update with varying data usage premium cross-device accounts and will launch when we believe we have the best data practices and storage ready for our users.</p>
          <p><b>Sign-up below to be the first to know as we launch this and other new features!</b></p>
        </div>
      </Modal>
    </div>
  );
};

export default Premium;