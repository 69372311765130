import React from 'react';
import Divider from '../divider/divider';
import styles from './modal.module.css';

interface Props {
  title?: string;
  subtitle?: string;
  className?: string;
}

const Modal: React.FC<Props> = props => {
  return (
    <div className={`${styles.wrap} ${props.className || ''}`}>
      <div className={styles.content}>
        {
          props.title ?
            <div className={styles.header}>
              <h1>{props.title}</h1>
              {props.subtitle ? <p className={styles.subtitle}>{props.subtitle}</p> : null}
              <Divider className={styles.divider} />
            </div>
            : null
        }
        {props.children}
      </div>
    </div>
  );
};

export default Modal;