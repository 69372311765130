import React from 'react';
import styles from './five-stars.module.css';
import {ReactComponent as Star} from '../../assets/star.svg';

interface Props {
  className?: string;
}

const FiveStars: React.FC<Props> = props => {
  return (
    <div className={`${styles.wrap} ${props.className || ''}`}>
      {
        new Array(5).fill(0).map((item, index) => <Star key={index} className={styles.star} />)
      }
    </div>
  );
};

export default FiveStars;