import React from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '../close-icon/close-icon';
import styles from './menu.module.css';

interface Props {
  open: boolean;
  onClose: () => void;
}

const Menu: React.FC<Props> = props => {
  return (
    <div className={`${styles.wrap} ${props.open ? styles.open : ''}`} onClick={props.onClose}>
      <div className={styles.menu} onClick={e => e.stopPropagation()}>
        {/*<Button
          className={styles.premiumPackagesButton}
          onClick={() => {
            props.onClose();
            navigate('/premium');
          }}
        >
          View Premium Packages
        </Button>*/}
        <br />
        <Link to="/about" onClick={() => props.onClose()}>About/Mission</Link>
        <Link to="/resources" onClick={() => props.onClose()}>Resources</Link>
        <Link to="/reviews" onClick={() => props.onClose()}>Reviews</Link>
        <Link to="/faq" onClick={() => props.onClose()}>FAQ's</Link>
        <Link to="/contact" onClick={() => props.onClose()}>Contact</Link>
        <br /><br /><br /><br />
        <a href="https://mindiful.education" target="_blank" rel="noreferrer">Mindiful.Education</a>
        <a href="https://mindiful.store" target="_blank" rel="noreferrer">Mindiful.Store</a>
        <CloseIcon className={styles.closeIcon} onClick={() => props.onClose()} />
      </div>
    </div>
  );
};

export default Menu;