const faqs: {question: string, answerMarkdown: string}[] = [
  {
    question: 'Why is my account data only showing on one device?',
    answerMarkdown: `Mindiful® is structured so that your sensitive data stays within your individual device unless you purchase a cross-device data-storage premium subscription.

    For data transparency and protection, we do NOT store the sensitive data of our free users. Because that data is only stored within an individual device, we cannot provide free user data across devices. 
    
    However, if you would like to manually transfer your data to another device, you can manually export all your data and import it to another device (Import/Export Guide) or purchase a premium subscription.
    
    Currently, premium subscriptions with cross-device data storage are under development but will be available in the near future!
    
    <a href="#footer">Sign-up here</a> to be the first to know as we launch this and other new features!`,
  },
  {
    question: 'How is my data protected?',
    answerMarkdown: `We built Mindiful®’s structure with your data protection in mind. All sensitive data is held within the user device or will be stored and protected with a premium cross-device subscription.

    Currently, cross-device data-storage subscriptions are under development but will be available in the near future!
    
    It is important to us to provide a progressive and exceptional tech product while also promoting transparency on why and how your data is handled with care. For any questions, please do not hesitate to reach out to us at <a href="mailto:hello@mindiful.io">hello@mindiful.io</a>.`,
  },
  {
    question: 'When are Premium Cross-Device Accounts Going to Be Available?',
    answerMarkdown: `At the core of our platform is our mission of providing free universal access to children’s mental health resources and being clear and transparent with data practices. With this mission in mind, we developed the free basic version first. 

    We are now developing our update with varying data usage premium cross-device accounts and will launch when we believe we have the best data practices and storage ready for our users.
    
    <a href="#footer">Sign-up here</a> to be the first to know as we launch this and other new features!`,
  },
  {
    question: 'Why do you collect my email?',
    answerMarkdown: `We collect your email, even with free local storage accounts, in order to encrypt/protect your data within your account. If not, anyone on your device could see it and we don’t want that!

    It is also important to us to keep in touch with our users. This is so that we may provide information on updates such as additional features, resources, and other important information; Including updates to our privacy policy/security or other technological advancements of Mindiful®.
    
    The tech industry itself and software applications are ever-growing and evolving. We always want to keep our users informed, feel cared for, and feel a part of the Mindiful® community. `,
  },
  {
    question: 'What devices are Mindiful® available on?',
    answerMarkdown: `Mindiful® is available on all devices!

    Mobile Application (iOS and Android)<br />
    Desktop Application(iOS and Android)<br />
    Web Application (Use Directly in Browser) `,
  },
  {
    question: 'How does Mindiful® work?',
    answerMarkdown: `Mindiful® is a progressive, enriching, and entertaining children’s mental health software platform comprised of early learning mental health exercises and insightful proprietary tools in an immersive storybook interface. 

    Simple interactive game-like educational experiences are divided into cloud mental health EXERCISES, a recorded/drawn JOURNAL, and ANALYTICS & INCENTIVIZATIONS.
    
    The Mindiful® Platform creates a calm, nurturing place for children to practice positive mental health techniques, be connected to resources, and parents/mental health professionals to gain insight/utilize tools to improve the trajectory of a child's mental health.`,
  },
  {
    question: 'Do you have resources to help understand the program?',
    answerMarkdown: `Yes! We have developed user guides as resources to help! There are many different ways to use the platform for long-term benefits.

    <a href="/resources">View our User-Guides.</a>`,
  },
  {
    question: 'Should I still backup my data if I have a premium account?',
    answerMarkdown: `No matter if you have a free account or premium account, we highly suggest incrementally backing up your data using our <a href="/Mindiful Data Backup Transfer Guide.pdf" target="_blank">import-export feature.</a> 

    We know how important your data is to you, and it is always a good practice in any software product to always have extra backups! `,
  },
  {
    question: 'How to earn the Keys to Positivity? (Milestones)',
    answerMarkdown: `A 'Key to Positivity' is earned every time a milestone is completed.

    Milestones:
    
    Bird Breathing - Complete the 3 Breathing Exercises
    Monkey Moods - Complete 3 Mood Questions
    Sloth Stretches - Complete 3 Stretches
    Reptile Relaxation - Complete the 3 Relaxation Recordings
    Journals - Complete 7 Entries
    
    Additional keys will open with upcoming version updates. 
    
    We also encourage adults to reinforce the themes within the program by the attachment of 'real-life' impactful goals to unlocking our 'Keys to Positivity.'`,
  },
  {
    question: 'What languages are Mindiful® available in?',
    answerMarkdown: `English and Spanish.

    To request another language, please contact us at <a href="mailto:hello@mindiful.io">hello@mindiful.io</a>. `,
  },
  {
    question: 'I am a free account user, which devices do you recommend I use Mindiful® on?',
    answerMarkdown: `We recommend ONLY using our mobile or desktop applications and NOT the web browser for free accounts.

    Because we do not store the sensitive data of free account users, we cannot store your data inside the web application without a cross-device storage premium account (coming soon). A free account’s data will automatically be deleted whenever you clear your web browser and that data cannot be recovered. 
    
    If you use our mobile or desktop application with a free account, your data is stored and protected on your individual device and may be manually backed up with our <a href="/Mindiful Data Backup Transfer Guide.pdf" target="_blank">import/export feature.</a>`,
  },
  {
    question: 'I am a free account user and would like to move my data to a different/new device what can I do?',
    answerMarkdown: `You will need to manually export and import that data whenever you change to different or new devices. We cannot do this for you without a cross-device premium account because the data is ONLY stored on your individual device for data safety. 

    For example, if you have a free account and were to upgrade your device, you would need to backup your data and import it to the new device. For your convenience, <a href="/Mindiful Data Backup Transfer Guide.pdf" target="_blank">here is a guide to export/import your data.</a>`,
  },
  {
    question: 'How do I delete my account?',
    answerMarkdown: `We have special measures set up to help avoid a child accidentally deleting their data.

    Within My Account, accessible through the key symbol in the upper left hand corner of the home screen, simply press Edit Profile and a red X will appear. Then tap the ‘X’ of the profile you would like to delete. 
    You will be asked to checkmark and type the child’s name to confirm. (The name needs to match the spelling under their avatar/photo.)
    
    Please note this is a permanent action and cannot be undone.
    
    If you would like to delete the entire account, with all profiles at once, simply delete the app from your device or press 'Edit Account' and the 'Delete Account' button is located in the upper left corner. 
    
    We highly suggest backing up your data before deleting it in case you change your mind. <a href="/Mindiful Data Backup Transfer Guide.pdf" target="_blank">Here is a guide to export your data for a backup.</a>`,
  },
  {
    question: 'Once I delete an account, can I recover my data?',
    answerMarkdown: `No. Once an account, or a child within an account, is deleted we do not have any access to recover it. 

    We highly suggest backing up your data incrementally and especially before deleting an account in case you change your mind. <a href="/Mindiful Data Backup Transfer Guide.pdf" target="_blank">Here is a guide to export your data for a backup.</a>`,
  },
  {
    question: 'I am passionate about children’s mental health, how can I help?',
    answerMarkdown: `Spread the word! We are an independent tech company and your recommendation means the world to us.  

    Send our link to a friend, share on social, write us a review on the app store, whatever you feel comfortable doing helps tremendously. No act is too small and together we can grow and get resources into the hands of children globally.
    
    <a href="/resources#sharable">Here is our sharable content</a> if you would like!`,
  },
  {
    question: 'I am a children’s mental health professional/organization, how can I help?',
    answerMarkdown: `We have a wonderful community of children’s mental health professionals that contribute to our development by recommending needed features/content, testing our updates, and helping spread Mindiful® as a valuable resource and tool for parents and clinicians alike. Our Mindiful® Mental Health Allies contribute truly wonderful feedback that is directly implemented.

    For more information on becoming a Mindiful® Mental Health Ally please contact us at <a href="mailto:hello@mindiful.io">hello@mindiful.io</a>.`,
  },
  {
    question: 'I have feedback! How do I contact you?',
    answerMarkdown: `We absolutely love receiving feedback. We are an independent tech company rooted in putting human-first tech on the map. Whatever you love, would like added, or we can improve upon, we are all ears. Always feel free to contact us at <a href="mailto:hello@mindiful.io">hello@mindiful.io</a>.`,
  },
];

export default faqs;