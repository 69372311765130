import React from 'react';
import styles from './close-icon.module.css';

interface Props {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const CloseIcon: React.FC<Props> = props => {
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <div className={`${styles.wrap} ${props.className || ''}`} onClick={onClick} style={{cursor: props.onClick ? 'pointer' : 'default'}}>
      <span>x</span>
    </div>
  );
};

export default CloseIcon;