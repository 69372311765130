import React from 'react';
import styles from './accessibility.module.css';
import Modal from '../../components/modal/modal';
import showdown from 'showdown';
import accessibility from '../../markdown/accessibility.md';

const MarkdownConverter = new showdown.Converter();

const Accessibility: React.FC = () => {
  const [contentHtml, setContentHtml] = React.useState<string>();

  React.useEffect(() => {
    fetch(accessibility)
      .then(r => r.text())
      .then(content => setContentHtml(MarkdownConverter.makeHtml(content)));
  }, []);

  return (
    <div className={styles.wrap}>
      <Modal className={styles.modal} title="Accessibility Statement">
        {
          contentHtml ?
            <div dangerouslySetInnerHTML={{__html: contentHtml}} />
            : null
        }
      </Modal>
    </div>
  );
};

export default Accessibility;