import React from 'react';
import Input from '../input/input';
import styles from './footer.module.css';
import { ReactComponent as FacebookLogo } from '../../assets/facebook.svg';
import { ReactComponent as InstagramLogo } from '../../assets/instagram.svg';
import { Link } from 'react-router-dom';

const validateEmail = (email: string): boolean => {
  return !!email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

const Footer: React.FC = () => {
  const [email, setEmail] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);

  const addContact = async () => {
    setError('');
    if (validateEmail(email)) {
      const response = await fetch(`${process.env.REACT_APP_API}/mailchimp-add-user`, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });
      const result = await response.json();
      if (!result.success) {
        setError(result.error || 'An unexpected error has occurred. Please try again.');
      } else {
        setIsSuccess(true);
      }
    } else {
      setError('Invalid email.');
    }
  };

  return (
    <div className={styles.footer} id="footer">
      <div>
        <p className={styles.join}>Join our community!</p>
        <div>
          <Input
            className={styles.input}
            value={email}
            type="email"
            placeholder="Email"
            onChange={val => setEmail(val)}
            submitText="Subscribe"
            onSubmit={() => addContact()}
            disabled={isSuccess}
          />
          {error ? <p className={styles.error}>{error}</p> : null}
          {isSuccess ? <p className={styles.success}>You have successfully subscribed.</p> : null}
        </div>
        <a href="https://www.facebook.com/mindiful" target="_blank" rel="noreferrer">
          <FacebookLogo className={styles.facebookIcon} />
        </a>
        <a href="https://www.instagram.com/mindiful.io" target="_blank" rel="noreferrer">
          <InstagramLogo className={styles.instagramIcon} />
        </a>
        <a className={styles.instaUser} href="https://www.instagram.com/mindiful.io" target="_blank" rel="noreferrer">@mindiful.io</a>
      </div>
      <div>
        <p>© 2022 - {new Date().getFullYear()} Whengen</p>
        <Link to="/privacy-policy">Privacy</Link>
        <Link to="/terms-of-use">Terms</Link>
        <Link to="/accessibility">Accessibility</Link>
        <Link to="/do-not-sell">Do Not Sell Info</Link>
        <Link to="/contact">Contact</Link>
      </div>
    </div>
  );
};

export default Footer;