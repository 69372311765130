import React from 'react';
import styles from './faq.module.css';
import Modal from '../../components/modal/modal';
import showdown from 'showdown';
import answersAll from './answers';
import Search from '../../components/search/search';
import { ReactComponent as ChevronDown } from '../../assets/chevron-down.svg';

const MarkdownConverter = new showdown.Converter();

const Faq: React.FC = () => {
  const [search, setSearch] = React.useState<string>('');
  const [answers, setAnswers] = React.useState(answersAll);

  React.useEffect(() => {
    if (search) {
      const answers = answersAll.filter(i => i.question.includes(search) || i.answerMarkdown.includes(search));
      setAnswers(answers);
    } else {
      setAnswers(answersAll);
    }
  }, [search]);
  
  return (
    <div className={styles.wrap}>
      <Modal className={styles.modal} title="FAQs">
        <Search className={styles.search} value={search} onChange={val => setSearch(val)} />
        <div className={styles.content}>
          {
            answers.map(item => (
              <div key={item.question} className={styles.question} onClick={e => e.currentTarget.classList.toggle(styles.active)}>
                <div className={styles.header}>
                  <h2>{item.question}</h2>
                  <ChevronDown className={styles.chevronDown} />
                </div>
                <div className={styles.answer} dangerouslySetInnerHTML={{__html: MarkdownConverter.makeHtml(item.answerMarkdown.replaceAll('  ', ''))}} />
              </div>
            ))
          }
        </div>
      </Modal>
    </div>
  );
};

export default Faq;